.presets-card.ant-card {
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 270px;
}

.presets-card .ant-card-meta-detail {
  display: flex;
  flex-direction: column;
}

.presets-card .ant-card-body {
  padding: 15px;
}

.presets-card .ant-card-body .ant-card-meta {
  height: 100%;
}

.presets-card .ant-card-meta-title {
  font-size: 14px;
  white-space: unset;
  overflow: visible;
}

.presets-card .ant-card-meta-description {
  flex: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  height: 100%;
}

.presets-card .ant-card-meta-description:has(.ant-tag) {
  min-height: 120px;
}

.presets-card .ant-card-actions .ant-btn {
  padding: 4px 8px;
  border-radius: 8px;
}

.presets-card .ant-card-actions .helper-popup-btn {
  width: 85%; 
}

.presets-card .presets-card__tag {
  height: 18px;
  font-size: 10px;
  line-height: 15px;
  border-radius: 4px;
}
